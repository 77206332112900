.body-container{
    height: inherit;
}
.layout-container{
    height: inherit;
}
.content-container{
    display: flex;
    flex-direction: column;
    padding: 0px;
    min-height: 100vh;
    // height: calc(100% - 45px);
}
.main-container{
    display: flex;
    flex-direction: column;
    // width: 100%;
    flex: auto;
}
.header-container{
    padding: 20px 30px 10px 90px;
}
.outlet-container{
    padding: 0px 30px 0px 90px;
    // height: calc(100% - 120px);
}
.mobile-container{
    padding: 80px 20px 10px 20px;
    height: calc(100% - 90px);
}
.footer-container{
    display: flex;
    padding: 0px 20px 0px 100px;
    min-height: 50px;
    background: #ffffff;
    border-top: 0px solid;
    border-top-color: #f0f0f0;
    bottom: 0;
    // position: fixed;
    // width: 100%;
}
html, body{
    min-height: 100% !important;
    height: 100%;
}