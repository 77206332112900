.sliderphoto{
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 100%;
  background-color: #F0F0F0;
}
.mobilephoto{
  // width: 100px;
  // height: 100px;
  // object-fit: cover;
  border-radius: 100%;
}

.userBox{
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userImage{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: solid 6px;
  background-size: cover;
}
.userNote{
  z-index: 999;
  position: absolute;
  bottom: 0px;
  right: 15px;
  width: 40px;
}

.menuIcon {
  min-width: 50px;
}
.menuIcon :hover{
  cursor: pointer;
  // cursor: "not-allowed";
}

.eventMarquee {
  width: calc(100%);
  text-align: end;
  padding-right: 30px;
  color: #737791;
  font-size: 16px;
  line-height: 20px;
  height: 20px;
  margin-bottom: 10px;
}
.eventContainer {
  // margin: 12px 10px;
  border: 3px solid;
  border-radius: 20px;
  width: calc(50% - 65px);
  padding: 10px;
  height: 55px;
  // min-width: 240px; 
}
.eventNull{
  border-color: #CCCCCC;
  background-color: #FFFFFF;
  // width: 305px;
}
.eventWell{
  border-color: #94DEB4;
  background-color: #EEF7EE;
  // width: 305px;
}
.eventAlert{
  border-color: #F89898;
  background-color: #FFF0F0;
  // width: 305px;
}
.eventInfo{
  width: 100%;
  height: inherit;
  position: relative;
  display: flex;
  // justify-content: center;
  align-items: center;
}
.eventImg{
  margin-left: 5px;
  width: 40px;
  height: 40px;
  background-size: contain ;
  background-repeat: no-repeat;
}
.eventNote{
  z-index: 999;
  position: absolute;
  bottom: 10px;
  left: 20px;
  width: 16px;
}
.eventTitle{
  color: #737791;
  font-weight: bold;
  margin-bottom: 3px;
  font-size: 16px;
  line-height: 20px;
}
.eventContext{
  color: #424962;
  font-weight: bold;
  font-size: 16px;
}
.eventTime{
  color: #424962;
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;
  // margin-top: 5px !important;
}

.tagSelected{
  background-color: #0A87A9;
  border: 1px solid #0A87A9;
}
.tagUnselected{
  background-color: #CCCCCC;
  border: 1px solid #CCCCCC;
}
.tagOpenPage{
  border: 1px solid #424962;
  color: #424962;
  display: flex;
  border-radius: 10px;
  cursor: pointer;
  width: 105px;
  margin: 1px;
  padding: 7px 0px 4px 0px;
  justify-content: center;
}
.summaryTagStyle {
  display: flex;
  align-items: baseline;
  margin: 2px 10px;
  padding: 7px 15px;
  border-radius: 25px;
  // width: 100px;
  justify-content: center;
  cursor: pointer;
  width: 'fit-content';
  // height: 30px;
}
.summaryTagText{
  line-height: 20px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  color: white;
}
.summaryTimeStyle {
  margin: 5px 15px 10px 15px;
  width: calc(100% - 320px);
  height: 40px;
  min-height: 40px;
  padding: 10px 10px 0px 10px;
  position: relative;
}
.summaryBlockStyle {
  margin: 10px 15px 15px 15px;
  border: 6px solid;
  border-radius: 20px;
  width: calc(50% - 200px);
  height: calc(50% - 90px);
  min-height: 165px;
  padding: 10px 10px 0px 10px;
  position: relative;
}
.summaryWell{
  border-color: #D6ECD6;
}
.summaryAlert{
  border-color: #FFE8E8;
}
.summaryNull{
  border-color: #CCCCCC;
}
.summaryDataBox{
  justify-content: center;
  display: grid;
  margin-top: 10px;
}
.summaryDataTitle{
  line-height: 20px;
  height: 20px;
  font-size: 16px;
  color: #737791;
}
.summaryDataWell{
  font-weight: bold;
  font-size: 40px;
  color: #737791;
}
.summaryDataAlert{
  font-weight: bold;
  font-size: 40px;
  color: #e23939;
}
.summaryDataUnit{
  display: grid;
  justify-content: end;
  position: absolute;
  bottom: 10px;
  right: 15px;
  // margin-bottom: 5px;
}
.unitText{
  font-size: 14px;
  color: #737791;
}