.listContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    max-height: 300px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b8d3db;
      border: 3px solid #f1f1f1;
      border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #b8d3db;
    }
  }
