$color-black: #424962;
$color-grey: #737791;
$color-white: #ebf5ff;
$color-blue: #0087a9;

$colors: (
  black: $color-black,
  grey: $color-grey,
  white: $color-white,
  blue: $color-blue
);

.avatarGroup {
  margin: 0 16px 0 10px;
  display: flex;
  align-items: center;
}

.avatar {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
}

.more {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid white;
  margin-left: -25px;
  margin-bottom: -15px;
  background-color: map-get($colors, white);
  display: flex;
  justify-content: center;
  align-items: center;
  color: map-get($colors, blue);
  font-weight: 600;
  line-height: 1.67;
  font-size: 12px;
}