.headerBox{
    height: 80px;
    padding: 5px;
    display: 'flex';
    width: 100%;

    // span{
    //     color: #568e8f;
    //     font-weight: 600;
    //     font-size: 1rem;
    //     line-height: 1.5;
    //     font-family: 'Public Sans',sans-serif;
    // }
    @media screen and (max-width: 1440px) {
        padding: 3px;
    }
}




