$color-info: #0A87A9;
$color-white: white;
$color-grey: #696C8D;
$color-green: #4ED1C5;
$color-orange: #FE957A;
$color-purple: #ACB9E8;
$color-pink: #CA6B6E;
$color-stage-green: #EEF7EE;
$color-stage-orange: #FFF4DE;
$color-stage-purple: #E9E7FC;
$color-stage-pink: #FFF4F4;
$color-webkit-white: #F1F1F1;
$color-webkit-blue: #B8D3DB;

$colors: (
  info: $color-info,
  white: $color-white,
  grey: $color-grey,
  green:$color-green,
  orange: $color-orange,
  purple: $color-purple,
  pink:$color-pink,
  stage-green: $color-stage-green,
  stage-orange: $color-stage-orange,
  stage-purple: $color-stage-purple,
  stage-pink: $color-stage-pink,
  webkit-white: $color-webkit-white,
  webkit-blue: $color-webkit-blue
);

@mixin dayContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 88px;
  border-radius: 28px;
  cursor: pointer;

  @media screen and (max-width: 1440px) {
    width: 50px;
    height: 70px;
    border-radius: 35px;
    margin: 0 3px;
  }
}

@mixin stageColor($color, $borderColor) {
  background-color: map-get($colors, $color);
  border-left: 10px solid map-get($colors, $borderColor);
}

@mixin arrow($margin) {
  text-align: center;
  cursor: pointer;
  padding-top: 10px;
  width: 24px;
  height: 24px;
  margin: $margin;
}

.headerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 28px;

  .arrowLeft {
    @include arrow(0 0 0 38px);
  }

  .arrowRight {
    @include arrow(0 38px 0 0);
  }

  .title {
    position: relative;
    top: 6px;
    width: 92px;
    height: 20px;
    margin: 1px 38px 3px;
    text-align: center;
    color: map-get($colors, info);
    font-size: 18px;
    font-weight: 600;
    line-height: 1.11;
  }
}

.calendarDaysContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px 20px 22px 25px;

  .daysContainer,
  .selectedDayContainer {
    @include dayContainer;
  }

  .daysContainer {
    background-color: map-get($colors, white);
  }

  .selectedDayContainer {
    background-color: map-get($colors, info);
  }

  .daysName {
    color: map-get($colors, info);
    font-size: 20px;

    @media screen and (max-width: 1440px) {
      font-size: 15px;
    }
  }

  .selectedDayName {
    color: map-get($colors, white);
    font-size: 20px;

    @media screen and (max-width: 1440px) {
      font-size: 15px;
    }
  }

  .daysCircle {
    background: map-get($colors, white);
    border-radius: 50%;
    width: 43px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: map-get($colors, info);
    font-weight: bold;
    font-size: 24px;

    @media screen and (max-width: 1440px) {
      width: 30px;
      height: 30px;
      font-size: 20px
    }
  }
}

.tableContainer {
  max-height: 555px;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: map-get($colors, webkit-white);
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: map-get($colors, webkit-blue);
    border: 3px solid map-get($colors, webkit-white);
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: map-get($colors, webkit-blue);
  }

  @media screen and (max-width: 1440px) {
    max-height: 570px;
    overflow-x: auto;
  }

  table {
    width: 100%;
    max-width: 50rem;

    tr:nth-child(even) th {
      visibility: hidden;
    }

    th,
    td {
      padding: 0.5rem 1rem;
    }

    th {
      width: 18%;
      font-size: 16px;
      line-height: 1.25;
      font-weight: normal;
      color: map-get($colors, info);
    }

    td {
      position: relative;
      color: map-get($colors, info);
      line-height: 1.4;
      border-radius: 0.5rem;
    }

    td>div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    td>div>div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 14px;
      line-height: 1.43;
      font-weight: 600;
      margin-bottom: 5px;
      margin: 0 15px 0 0;
      width: 140px;
    }

    td>div>div>span {
      font-size: 12px;
      line-height: 1.67;
      color: map-get($colors, grey);
      font-weight: normal;
      width: 100%;
    }



    @media screen and (max-width: 1440px) {
      max-width: 100%;

      th,
      td {
        padding: 0.2rem 0.4rem;
      }

      th {
        width: 23%;
        font-size: 16px;
      }

      td {
        font-size: 1rem;
        line-height: 1;
      }

      td>div>div {
        min-width: 100px;
      }
    }
  }
}

.profileImage {
  width: 30px;
  height: 30px;
  margin: 0 0 20px 0;
  border-radius: 50%
}

.stage-green {
  @include stageColor(stage-green, green);
}

.stage-orange {
  @include stageColor(stage-orange, orange);
}

.stage-purple {
  @include stageColor(stage-purple, purple);
}

.stage-pink {
  @include stageColor(stage-pink, pink);
}