.profileIcon :hover{
    // cursor: pointer;
    cursor: "not-allowed";
}

.timeline{
    width: 50px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: rgb(115, 119, 145, 0.9);// #737791;
    color: white;
    font-weight: bold;
    padding: 10px;
    margin: 0px 20px;
}

.timelineObj1::after{
  content: '';
  position: relative;
  right: 53px;
  top: -20px;
  width: 35px;
  height: 1px; /* 線條的高度 */
  background-color: #BAD4DD; /* 線條的顏色 */
}

.timelineObj2::after{
    content: '';
    position: relative;
    left: 53px;
    top: -20px;
    width: 35px;
    height: 1px; /* 線條的高度 */
    background-color: #BAD4DD; /* 線條的顏色 */
}

.usercardContainer{
    margin-bottom: 20px;
    border-radius: 20px;
    height: 120px;
}
.usercardBox{
    display: flex;
    height: 100%;
    // cursor: pointer;
}
.usercardPhoto{
    width: 45%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.usercardInfo{
    width: 55%;
    display: flex;
    align-items: center;
}
.profileImage{
    border-radius: 50%;
    border: solid 5px;
    background-size: cover;
}
.profileNote{
    z-index: 999;
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 25px;
}

// .profileImage {
//     position: relative;
//     width: 70px;
//     height: 70px;
//     border-radius: 50%;
//     border: solid 5px;
//     background-size: cover;
// }

// .profileState {
//     position: absolute;
//     width: 25px;
//     height: 25px;
//     border: 1px solid white;
//     border-radius: 50%;
//     right: 0px;
//     top: 0px;
//     transform: translate(35%, 150%);
//     background-size: cover;
// }