$color-black: #424962;
$color-grey: #737791;
$color-white: #ebf5ff;
$color-blue: #0087a9;

$colors: (
  black: $color-black,
  grey: $color-grey,
  white: $color-white,
  blue: $color-blue
);

.calendarIcon {
  height: 24px;
  width: 24px;
}

.scheduleListContainer {
  overflow: auto;
  max-height: 500px;

  @media (max-width: 1440px) and (max-height: 960px) {
    max-height: 400px;
  }
}

.scheduleList {
  position: relative;
  margin: 10px 0;
  border-left: 8px solid;
  border-radius: 5px;

  .scheduleItem {
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    position: relative;
    border-radius: 0 5px 5px 0;

    &Activity {
      display: flex;
      align-items: center;

      .info {
        .title {
          width: 100%;
          height: 20px;
          font-weight: 600;
          font-size: 16px;
          color: map-get($colors, black);
          line-height: 1.25;
        }

        .subtitle {
          width: 100%;
          height: 20px;
          font-size: 14px;
          color: map-get($colors, grey);
          line-height: 1.43;

          >span {
            margin-right: 3px;
          }
        }
      }
    }

    &TimeLine {
      margin-right: 10px;

      .allDay {
        width: 100%;
        height: 20px;
        font-size: 14px;
        font-weight: 600;
        color: map-get($colors, grey);
        line-height: 1.43;
      }

      .startTime {
        width: 100%;
        height: 20px;
        font-size: 14px;
        font-weight: 600;
        color: map-get($colors, grey);
        line-height: 1.43;
      }

      .endTime {
        width: 100%;
        height: 20px;
        display: flex;
        flex-direction: row-reverse;
        font-size: 12px;
        color: map-get($colors, grey);
        line-height: 1.67;
      }
    }
  }
}

.avatarGroup {
  margin: 0 16px 0 10px;
  display: flex;
  align-items: center;
}

.avatar {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
}

.more {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid white;
  margin-left: -25px;
  margin-bottom: -15px;
  background-color: map-get($colors, white);
  display: flex;
  justify-content: center;
  align-items: center;
  color: map-get($colors, blue);
  font-weight: 600;
  line-height: 1.67;
  font-size: 12px;
}