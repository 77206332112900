.custom-modal {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1200;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding: 150px 0px;
  overflow-y: scroll;
  
  @media (max-width: 768px) {
    padding: 30px 0px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 40px 0px;
  }

  @media (min-width: 1024px) and (max-width: 1440px) {
    padding: 50px 0px;
  }

  @media (min-width: 1441px)and (max-width: 1920px) {
    padding: 60px 0px;
  }
}

.custom-modal-content {
  background-color: white;
  width: 70%;
  margin: auto;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 4px 4px 8px rgb(0 0 0 / 60%);
  position: relative;
  overflow: visible;
}


/* modal width */
.custom-modal-content.modal-auto {
  width: 80%;
  max-width: 1000px;
  min-width: 600px;

  @media screen and (max-width: 450px) {
    width: 60% !important;
    max-width: 250px;
    min-width: 150px;
  }
}

.custom-modal-content.modal-750px {
  width: 80%;
  max-width: 750px;
  min-width: 450px;

  @media screen and (max-width: 450px) {
    width: 60% !important;
    max-width: 250px;
    min-width: 150px;
  }
}

.custom-modal-content.modal-550px {
  width: 80%;
  max-width: 550px;
  min-width: 350px;

  @media screen and (max-width: 450px) {
    width: 60% !important;
    max-width: 250px;
    min-width: 150px;
  }
}

// Splash page modal
// .custom-modal-content.modal-90percent {
//   width: 90% !important;
//   display: flex;
//   flex-direction: column;
//   height: 100%;

//   @media screen and (max-height: 1200px) {
//     display: block;
//     height: auto;
//   }
// }