.draggableItem {
  user-select: none;
  margin: 0 0 8px 10px;
  padding: 16px;
  border: 1px solid #f0f0f0;
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
}

.draggableItem.Edit:hover {
  background-color: #ebf5ff;
}

.videoImg {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.videoPlayBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
}