$label-color: #077DA4;
$add-icon-color: #0087A9;
$add-icon-bg: white;

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin circle($size) {
  @include size($size, $size);
  border-radius: 50%;
}

.labelField {
  color: $label-color;
}

.textareaContainer {
  position: relative;
  width: 95%;
}

.textarea {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  border-color: #EEF0F2;
  padding: 10px;
  overflow: auto;

  &::placeholder {
    color: #9E9E9E;
    opacity: 1;
  }

  &:hover {
    border-color: #252525;
  }

  .textareaHelperText {
    color: red;
    font-size: 12px;
  }
}

.customAddIcon {
  position: relative;
  margin: 0 8px;
  @include size(20px, 20px);
  background-color: $add-icon-bg;
  @include circle(20px);
  @include flex-center;

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: $add-icon-color;
    border-radius: 5px;
  }

  &::before {
    width: 60%;
    height: 15%;
  }

  &::after {
    width: 15%;
    height: 60%;
  }
}