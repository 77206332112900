.sideBox{
    height: 60px;
    background: #ffffff;
    border-bottom: 1px solid;
    border-bottom-color: #f0f0f0;
    width: 100%;
    z-index: 1200;
    position: fixed;
    top: 0;
    left: 0;
}
.listBox:hover{
    cursor: pointer;
}

.drawerItem,
.drawerItemSelected{
    width: 100%;
    height: 45px;
    // cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin: 0px 10px;
    padding-left: 5px;
}

.drawerItemSelected{
    background-color: #0A87A9;
    color: white;
    font-weight: bold;
}

.drawerItem:hover{
    background-color: #EEF0F2;
}