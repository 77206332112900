.cardContainer {
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.title {
  font-size: large;
  color: #058aab;
  font-weight: 600;
}

.datePicker {
  width: 150px;

  .MuiOutlinedInput-root {
    & fieldset {
      border-color: #bebebe;
      margin: 5px 0px;
    }

    &:hover fieldset {
      border-color: #bebebe;
    }

    &.Mui-focused fieldset {
      border-color: #bebebe;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #bebebe;
    border-radius: 12px;
  }
}
