$profile-size: 70px;
$profile-border-width: 5px;
$profile-state-size: 25px;
$medicine-icon-size: 5rem;
$medicine-edit-icon-size: 2rem;
$label-color: #077DA4;
$add-icon-color: #0087A9;
$add-icon-bg: white;

@mixin size($width, $height) {
    width: $width;
    height: $height;
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin circle($size) {
    @include size($size, $size);
    border-radius: 50%;
}

@mixin absolute-center($x, $y) {
    position: absolute;
    transform: translate($x, $y);
}

.profileImage {
    position: relative;
    @include size($profile-size, $profile-size);
    @include circle($profile-size);
    border: solid $profile-border-width;
    background-size: cover;
}

.profileState {
    position: absolute;
    @include size($profile-state-size, $profile-state-size);
    border: 1px solid white;
    @include circle($profile-state-size);
    right: 0;
    top: 0;
    @include absolute-center(35%, 150%);
    background-size: cover;
}

.mainIcon {
    @include size($medicine-icon-size, $medicine-icon-size);
    margin: 1rem;
}

.medicineIconContainer {
    @include size($medicine-icon-size, $medicine-icon-size);
    margin: 1rem;
    @include circle($medicine-icon-size);
    @include flex-center;
    cursor: pointer;

    &:hover .medicineIconHover {
        opacity: 0.6;
    }
}

.medicineIcon {
    @include size($medicine-icon-size, $medicine-icon-size);
    display: block;
    position: absolute;
}

.medicineIconHover {
    @include size($medicine-icon-size, $medicine-icon-size);
    opacity: 0;
    transition: 0.5s ease;
    background-color: #CCCCCC;
    position: relative;
    @include circle($medicine-icon-size);
    @include flex-center;
}

.medicineEditIcon {
    @include size($medicine-edit-icon-size, $medicine-edit-icon-size);
}

.labelField {
    color: $label-color;
}

.customAddIcon {
    position: relative;
    margin: 0 8px;
    @include size(20px, 20px);
    background-color: $add-icon-bg;
    @include circle(20px);
    @include flex-center;

    &::before,
    &::after {
        content: '';
        position: absolute;
        background-color: $add-icon-color;
        border-radius: 5px;
    }

    &::before {
        width: 60%;
        height: 15%;
    }

    &::after {
        width: 15%;
        height: 60%;
    }
}