.aibox-image-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px !important;
}

.aibox-image {
  width: 80px;
  height: 68px;
  margin: 0 8px 0 0;
  object-fit: contain;
}

.aibox-info-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 8px !important;
}

.aibox-title {
  color: #078aab;
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
}

.aibox-status {
  color: #42cfc1;
  font-size: 16px;
  font-weight: 500;
}
