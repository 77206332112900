.listContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    max-height: 350px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b8d3db;
      border: 3px solid #f1f1f1;
      border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #b8d3db;
    }
}

.usercardPhoto{
    // width: 45%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profileImage{
    border-radius: 50%;
    border: solid 5px;
    background-size: cover;
}
.profileNote{
  z-index: 999;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 25px;
}


// .profileImage{
//   border-radius: 50%;
//   border: solid 5px;
//   border-color: #CB6B6E;
//   background-size: cover;
// }

.profileIcon :hover{
    cursor: pointer;
}
  